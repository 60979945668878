import React, { useEffect, useState, useContext } from 'react';
import Context from '../Context';
import { Manager } from 'javascript/models';
import FormRow from 'components/shared/forms/FormRow';
import ReactSelect from 'components/shared/forms/ReactSelect';
import Select from 'react-select';

export default function adminInfo() {
  const context = useContext(Context);

  const { portfolio } = context.state;
  const { adminSystem, adminSystemReference, custodianReference, jobstreamPortfolioNumber } = portfolio;
  const { adminSystemOptions, companyId } = context.props;

  const [managerOptions, setManagerOptions] = useState([]);
  const [managerSearchInput, setManagerSearchInput] = useState('');
  const [selectedCustodian, setSelectedCustodian] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (portfolio && portfolio.custodian) {
      setSelectedCustodian({ label: portfolio.custodian.name, value: portfolio.custodian.id });
    }
  }, [portfolio]);

  useEffect(() => {
    getManagers();
  }, [managerSearchInput]);

  const defaultAdminSystem = adminSystemOptions.find((option) => option.value === adminSystem);

  async function getManagers() {
    setIsLoading(true);
    const { data } = await Manager.where({ company_id: companyId })
      .where({ selectable: true })
      .where({ search: { match: managerSearchInput } })
      .all();

    const newManagerOptions = data.map((manager) => ({ value: manager.id, label: manager.name }));
    setManagerOptions(newManagerOptions);
    setIsLoading(false);
  }

  function handleCustodianSearchChange(input) {
    setManagerSearchInput(input);
  }

  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Admin Information</h4>
      <div className="frow frow--gutters">
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="admin_system" className="form__label">
            Admin System
          </label>
          <ReactSelect
            defaultValue={defaultAdminSystem}
            name="portfolio[admin_system]"
            id="portfolio_admin_system"
            isClearable
            options={adminSystemOptions}
            theme="dark"
          />
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="currency" className="form__label">
            Portfolio Admin Reference
          </label>
          <input type="text" name="portfolio[admin_system_reference]" defaultValue={adminSystemReference} />
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="currency" className="form__label">
            Jobstream Portfolio Number
          </label>
          <input type="text" name="portfolio[jobstream_portfolio_number]" defaultValue={jobstreamPortfolioNumber} />
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="custodian" className="form__label">
            Custodian
          </label>
          <Select
            className="react-select-dark"
            classNamePrefix="react-select-dark"
            value={selectedCustodian}
            isClearable
            isSearchable
            isLoading={isLoading}
            id="custodian_id"
            name="portfolio[custodian_id]"
            options={managerOptions}
            placeholder="Search for a Custodian to select"
            inputValue={managerSearchInput}
            onInputChange={handleCustodianSearchChange}
            onChange={(selectedOption) => setSelectedCustodian(selectedOption)}
          />
        </FormRow>
        <FormRow note="" className="col-mc-1-1">
          <label htmlFor="currency" className="form__label">
            Custodian Reference
          </label>
          <input type="text" name="portfolio[custodian_reference]" defaultValue={custodianReference} />
        </FormRow>
      </div>
    </fieldset>
  );
}

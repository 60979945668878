import * as React from 'react';

import Table from '../portfolios/Table';
import ManagerUserTable from '../managerUsers/Table';

interface IProps {
  clientTypes: string[];
  currencies: string[];
  isExportable: boolean;
  managerId: string;
  newUserPath: string;
  riskOptions: string[];
  statuses: string[];
}

export default function show(props: IProps) {
  const { managerId, clientTypes, currencies, isExportable, newUserPath, riskOptions, statuses } = props;

  const [selectedTab, setSelectedTab] = React.useState<'users' | 'portfolios' | 'custodians'>('portfolios');

  function renderTable() {
    if (selectedTab === 'users') {
      return <ManagerUserTable scope={{ manager_id: managerId }} newPath={newUserPath} />;
    } else if (selectedTab === 'custodians') {
      return (
        <Table
          {...{ currencies, clientTypes, isExportable, riskOptions, statuses }}
          scope={{ custodian_id: { eq: managerId } }}
        />
      );
    }

    return (
      <Table
        {...{ currencies, clientTypes, isExportable, riskOptions, statuses }}
        scope={{ manager_id: { eq: managerId } }}
      />
    );
  }

  function handleTabSelect() {
    setSelectedTab(this);
  }

  const classes = 'tabbed-nav__tab col-flex-grow-1 tabbed-nav__tab--right-bordered';

  return (
    <div className="platform-panel">
      <div className="tabbed-nav">
        <div
          className={`${classes} ${selectedTab === 'portfolios' ? 'tabbed-nav__tab--active' : ''}`}
          onClick={handleTabSelect.bind('portfolios')}
        >
          <span className="text-bold letter-spacing-1">Portfolios</span>
        </div>

        <div
          className={`${classes} ${selectedTab === 'users' ? 'tabbed-nav__tab--active' : ''}`}
          onClick={handleTabSelect.bind('users')}
        >
          <span className="text-bold letter-spacing-1">Users</span>
        </div>

        <div
          className={`${classes} ${selectedTab === 'custodians' ? 'tabbed-nav__tab--active' : ''}`}
          onClick={handleTabSelect.bind('custodians')}
        >
          <span className="text-bold letter-spacing-1">Custodians</span>
        </div>
      </div>
      <div className="platform-content platform-content--padding-all">{renderTable()}</div>
    </div>
  );
}

import * as React from 'react';

import { startCase, sortBy } from 'lodash';
import classNames from 'classnames';

import Frow from '../../../frow/Frow';
import Column from '../../../frow/Column';
import useVisibility from 'components/shared/customHooks/useVisibility';

import ExtraSettings from './portfolioInfo/ExtraSettings';
import LinkedBenchmarks from './portfolioInfo/LinkedBenchmarks';
import MandateInformation from './portfolioInfo/MandateInformation';
import Section from './portfolioInfo/Section';
import MandateFlags from './portfolioInfo/MandateFlags';

import useInvestmentPolicyStatementsModal from './UseInvestmentPolicyStatementModal';
import useMandatesModal from './UseMandateModal';
import StatusTag from './shared/StatusTag';
import clientServiceType from 'components/portal/clients/show/ClientServiceType';

export default function portfolioInfo({ portfolio }) {
  const {
    activeFee,
    adminSystem,
    adminSystemReference,
    answers,
    additionalReference,
    activePeriods,
    childPortfolios,
    clientName,
    clientType,
    combined,
    consolidated,
    currency,
    custodianName,
    custodianReference,
    dataChaseType,
    dataFrequency,
    emailAddress,
    entity,
    feeCurrencySymbol,
    investmentManagerReference,
    jobstreamPortfolioNumber,
    letterOfEngagementSignatureDate,
    managerName,
    parentPortfolios,
    primaryContact,
    mandateId,
    quantOnly,
    reviewFrequency,
    reviewPeriodYears,
    risk,
    serviceType,
    secondaryContact,
    slaDays,
    emapUsers,
    managerUsers,
    managerUserEmails,
    feeType,
  } = portfolio.attributes;

  const {
    applicationFormPath,
    riskProfilerPath,
    letterOfEngagementPath,
    suitabilityLetterPath,
    investmentObjectiveEvaluationPath,
  } = portfolio.links;

  const [isOpen, setIsOpen] = React.useState(false);
  const [mandate, setMandate] = React.useState(null);
  const [handleIPSOpen, InvestmentPolicyStatementModal] = useInvestmentPolicyStatementsModal({
    hideSave: false,
    portfolio,
  });
  const [handleMandateOpen, MandateModal] = useMandatesModal({ portfolio, hideSave: false });

  function toggleOpen() {
    setIsOpen((prevState) => !prevState);
  }

  function attachmentLink(attachmentPath, name) {
    if (attachmentPath) {
      return (
        <a href={attachmentPath} className="link link--secondary" target="_blank">
          View {name}
        </a>
      );
    }

    return <span>Document not uploaded</span>;
  }

  function letterOfEngagementDisplayValue() {
    if (clientType === 'Monitoring' || clientType === 'Consultancy') {
      return 'Refer to client level';
    } else {
      return attachmentLink(letterOfEngagementPath, 'Letter of Engagement');
    }
  }

  function valuePresent(value) {
    if (value) {
      return value;
    }
    return 'None Set';
  }

  const answerString = answers ? '(Question Included)' : '(Questions Excluded)';

  const client = [
    { name: 'Client', value: clientName },
    { name: 'Primary Contact', value: primaryContact },
    { name: 'Email Address', value: emailAddress },
    { name: 'Service Type', value: startCase(serviceType) },
    { name: 'Review Type', value: quantOnly ? 'Quantitative Only' : 'Qualitative' },
    { name: 'Questions', value: answerString },
  ];

  const setup = [
    { name: 'Entity', value: entity },
    { name: 'Manager', value: managerName },
    { name: 'Manager Reference', value: investmentManagerReference ? investmentManagerReference : 'None set' },
    { name: 'Additional Manager Reference', value: additionalReference ? additionalReference : 'None set' },
    { name: 'Currency / risk', value: valuePresent(currency) },
    { name: 'Risk', value: startCase(valuePresent(risk)) },
  ];

  const isPercentageFee = feeType === 'percentage';
  const feeString = `${!isPercentageFee ? feeCurrencySymbol : ''}${valuePresent(activeFee)}${isPercentageFee ? '%' : ''}`;

  const data = [
    { name: 'Data Frequency', value: dataFrequency },
    { name: 'Fee', value: feeString },
    { name: 'Review Frequency', value: startCase(reviewFrequency) },
    { name: 'Active Periods', value: activePeriods },
    { name: 'Review Time Period', value: `${reviewPeriodYears} ${reviewPeriodYears === 1 ? 'year' : 'years'}` },
    { name: 'SLA', value: `${slaDays} days` },
  ];

  const attachments = [
    {
      name: 'Mandate',
      value: (
        <a className="link link--secondary" onClick={handleMandateOpen as any}>
          Mandate files
        </a>
      ),
    },
    {
      name: 'IPS',
      value: (
        <a className="link link--secondary" onClick={handleIPSOpen as any}>
          IPS files
        </a>
      ),
    },
    { name: 'Monitoring Application', value: attachmentLink(applicationFormPath, 'Monitoring Application') },
    { name: 'Risk Profiler', value: attachmentLink(riskProfilerPath, 'Risk Profiler') },
    { name: 'Letter of Engagement', value: letterOfEngagementDisplayValue() },
    { name: 'Suitability Letter', value: attachmentLink(suitabilityLetterPath, 'Suitability Letter') },
    {
      name: 'Investment Objective Evaluation',
      value: attachmentLink(investmentObjectiveEvaluationPath, 'Investment Objective Evaluation'),
    },
  ];

  const formattedChildPortfolios = sortBy(childPortfolios, 'reference').map((portfolio) => (
    <Frow key={portfolio.reference} style={{ width: '100%', marginBottom: '2px' }} itemAlignment="baseline">
      <Column breakpointSize="mc" maxColumns={2} columnSpan={1}>
        <a className="link link--secondary" href={portfolio.link}>
          {portfolio.reference}
        </a>
      </Column>
      <Column breakpointSize="mc" maxColumns={2} columnSpan={1}>
        <StatusTag status={portfolio.status} modifiers={['small']} truncateLength={12} />
      </Column>
    </Frow>
  ));

  const formattedParentPortfolios = parentPortfolios.map((portfolio) => (
    <div key={portfolio.reference}>
      <a className="link link--secondary" href={portfolio.link}>
        {portfolio.reference}
      </a>
    </div>
  ));

  const components: { name: string; value: string | React.ReactElement; bordered?: boolean }[] = [
    { name: 'Consolidated', value: consolidated ? 'Yes' : 'No' },
    { name: 'Combined', value: combined ? 'Yes' : 'No' },
    { name: 'Constituent Portfolio', value: parentPortfolios.length ? 'Yes' : 'No', bordered: true },
  ];

  if (childPortfolios.length) {
    components.splice(2, 0, { name: 'Component Portfolios', value: <Frow>{formattedChildPortfolios}</Frow> });
  }

  if (parentPortfolios.length) {
    components.push({ name: 'Parent Portfolios', value: <div>{formattedParentPortfolios}</div> });
  }

  const contactDetails = [
    { name: 'Secondary Contact', value: secondaryContact },
    { name: 'E-MAP Contacts', value: emapUsers },
    {
      name: (
        <span>
          Data Contacts -{' '}
          <a
            className="text-brand-windows-blue"
            href={`mailto:${managerUserEmails.join(';')}?&subject=${portfolio.attributes.reference}`}
          >
            Compose Email
          </a>
        </span>
      ),
      value: managerUsers,
    },
    { name: 'Data Chase Type', value: startCase(dataChaseType) },
  ];

  const adminDetails = [
    { name: 'Admin System', value: adminSystem },
    { name: 'Admin System Reference', value: adminSystemReference },
    { name: 'Jobstream Number', value: jobstreamPortfolioNumber },
    { name: 'Custodian', value: custodianName },
    { name: 'Custodian Reference', value: custodianReference },
  ];

  const iconClasses = classNames('icon-arrow-down', 'icon-animated', 'icon-fw', 'icon-push-down-2', 'icon-1-3x', {
    'icon-flipped': isOpen,
  });

  function renderMoreInfo() {
    if (!isOpen) return;

    return (
      <React.Fragment>
        <div className="platform-content platform-content--spacing-horizontal platform-content--border-bottom h-100">
          <div className="frow">
            <Section attributes={attachments} bordered />
            <div>{MandateModal}</div>
            <div>{InvestmentPolicyStatementModal}</div>
            <Section attributes={components} bordered />
            <LinkedBenchmarks {...{ portfolio }} />
          </div>
        </div>
        <div className="platform-content platform-content--spacing-horizontal platform-content--border-bottom h-100">
          <div className="frow">
            <Section attributes={contactDetails} size={2} bordered />
            <Section attributes={adminDetails} bordered={false} />
          </div>
        </div>
        <div className="platform-content platform-content--spacing-horizontal platform-content--border-bottom h-100">
          <div className="frow">
            <MandateFlags {...{ mandate, setMandate, mandateId }} />
          </div>
        </div>
        <ExtraSettings {...{ isOpen, portfolio }} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="platform-content platform-content--spacing-horizontal platform-content--border-bottom h-100">
        <div className="frow">
          <Section attributes={client} bordered />
          <Section attributes={setup} bordered />
          <Section attributes={data} bordered={false} />
        </div>
      </div>
      <MandateInformation {...{ mandate, setMandate, mandateId, portfolio }} />
      {renderMoreInfo()}
      <div className="platform-panel__inner pad-t-0">
        <div className="platform-panel__inner pad-t-2">
          <div className="frow frow--items-center frow--justify-center">
            <a className="link" onClick={toggleOpen}>
              <span className="mar-r-1">Show {isOpen ? 'Less' : 'More'}</span>
              <i className={iconClasses} />
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
